<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import Stat from "./widget";
import BookingData from "./booking-data"


/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Stat,
    BookingData 

    // Sources,
    // RecentActivity,
    // RevenueLocation,
    // Chat,
    // Transaction
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Nomadic",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div>

        <Stat />

        <div class="row">
          <div class="col-md-6">
              <BookingData/>

          </div>
        </div>
      
 
 
  
    </div>
  </Layout>
</template>
